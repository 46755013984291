import { useMixin } from "./use_mixin";
import { useTimeout } from "./use_timeout";
import { isHTMLInputElement } from "@stimulus-library/utilities";
export function useTemporaryContent(controller, target, content, timeout, teardownCallback) {
    const setContent = (element, text) => {
        if (isHTMLInputElement(element)) {
            element.value = text;
        }
        else {
            element.textContent = text;
        }
    };
    const getContent = (element) => {
        return isHTMLInputElement(element) ? element.value : element.innerHTML;
    };
    let cleanupTimeout = () => void 0;
    const originalText = getContent(target);
    const teardown = () => {
        setContent(target, originalText);
        cleanupTimeout();
        if (teardownCallback) {
            teardownCallback.call(controller);
        }
    };
    const setup = () => {
        setContent(target, content);
        if (timeout !== undefined) {
            cleanupTimeout = useTimeout(controller, teardown, timeout);
        }
    };
    useMixin(controller, setup, teardown);
    return {
        teardown,
        update(newContent) {
            setContent(target, newContent);
        },
    };
}
