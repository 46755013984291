import { debounce, wrapArray } from "@stimulus-library/utilities";
import { useMixin } from "./use_mixin";
export function useEventListener(controller, element, eventNameOrNames, handler, opts) {
    if (opts?.debounce) {
        handler = debounce(handler.bind(controller), opts.debounce);
        delete opts.debounce;
    }
    else {
        handler = handler.bind(controller);
    }
    const eventNames = wrapArray(eventNameOrNames);
    const setup = () => eventNames.forEach(eventName => element.addEventListener(eventName, handler, opts));
    const teardown = () => eventNames.forEach(eventName => element.removeEventListener(eventName, handler));
    useMixin(controller, setup, teardown);
    return { setup, teardown };
}
export function useEventListeners(controller, element, eventNameOrNames, handler, opts) {
    return useEventListener(controller, element, eventNameOrNames, handler, opts);
}
export function useCollectionEventListener(controller, elements, eventNameOrNames, handler, opts) {
    const handlers = [];
    elements.forEach(el => {
        const { setup, teardown } = useEventListener(controller, el, eventNameOrNames, handler, opts);
        handlers.push({ setup, teardown });
    });
    return [
        () => handlers.forEach(h => h.setup()),
        () => handlers.forEach(h => h.teardown()),
    ];
}
export function useCollectionEventListeners(controller, elements, eventNameOrNames, handler, opts) {
    return useCollectionEventListener(controller, elements, eventNameOrNames, handler, opts);
}
