export function useMixin(controller, setup, teardown) {
    const controllerDisconnect = controller.disconnect.bind(controller);
    setup();
    Object.assign(controller, {
        disconnect() {
            teardown();
            controllerDisconnect();
        },
    });
    return controllerDisconnect;
}
