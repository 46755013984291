import { useMixin } from "./use_mixin";
export function useInterval(controller, handler, interval) {
    handler = handler.bind(controller);
    let intervalHandle = null;
    const setup = () => intervalHandle = setInterval(handler, interval);
    const teardown = () => {
        if (intervalHandle !== null) {
            clearInterval(intervalHandle);
        }
    };
    useMixin(controller, setup, teardown);
    return teardown;
}
