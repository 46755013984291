import { Controller } from "@hotwired/stimulus";
import { controllerMethod, pascalCase } from "@stimulus-library/utilities";
export class InstallClassMethodComposableController extends Controller {
}
function addMethodsForClassDefinition(controller, name) {
    const defaultElement = controller.element;
    const hasClass = () => controller[`has${pascalCase(name)}Class`] == true;
    const classes = () => controller[`${name}Classes`];
    const defaultClasses = () => controllerMethod(controller, `default${pascalCase(name)}Classes`).call(controller) || [];
    const classOrDefault = () => hasClass() ? classes() : defaultClasses();
    if (controller[`${name}Classes`] == undefined) {
        Object.defineProperty(controller, `${name}Classes`, {
            get: () => hasClass() ? controller[`${name}Class`].split(" ") : defaultClasses(),
        });
    }
    const methods = {
        [`add${pascalCase(name)}Classes`]: (element = defaultElement) => element.classList.add(...classOrDefault()),
        [`remove${pascalCase(name)}Classes`]: (element = defaultElement) => element.classList.remove(...classOrDefault()),
        [`${name}ClassesPresent`]: (element = defaultElement) => classOrDefault().every((klass) => element.classList.contains(klass)),
    };
    Object.assign(controller, methods);
}
export function installClassMethods(controller) {
    // @ts-ignore
    const classes = controller.constructor.classes || [];
    classes.forEach((classDefinition) => addMethodsForClassDefinition(controller, classDefinition));
}
